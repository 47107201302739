import React from 'react'
import PropTypes from 'prop-types'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { withStyles } from '@material-ui/core/styles'

import CheckboxStyled from './styledComponent'
import colors from '../../colors'

const StyledFormControl = withStyles({
  root: {
    marginRight: 0
  },
  label: {
    fontFamily: 'Prompt-Medium, sans-serif',
    fontSize: '12px'
  }
})(FormControlLabel)

const GreenCheckbox = withStyles({
  root: {
    fontFamily: 'Prompt',
    '&$checked': {
      color: colors.jadeGreen
    }
  },
  checked: {}
})(Checkbox)

const CheckboxComponent = (props) => {
  const onCheckboxCheck = (e) => {
    let tempCheckboxObject = {
      label: props.label,
      value: props.value,
      checked: e.target.checked
    }
    props.onChange(tempCheckboxObject)
  }

  return (
    <CheckboxStyled>
      <StyledFormControl
        control={<GreenCheckbox id={props.id} checked={props.checked} onChange={onCheckboxCheck} />}
        label={props.label}
        disabled={props.disabled}
      />
    </CheckboxStyled>
  )
}

CheckboxComponent.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  checked: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  value: PropTypes.string
}

export default CheckboxComponent
